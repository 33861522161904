body {
  margin: 0;
  padding: 0;
}

.Toastify__toast--dark {
  background: #121212 !important;
  color: #fff !important;
}

.Toastify__toast--default {
  background: #fff !important;
  color: #aaa !important;
}

.Toastify__toast--info {
  background: #497ed5 !important;
}

.Toastify__toast--success {
  background: #3e9957 !important;
}

.Toastify__toast--warning {
  background: #d9a81a !important;
}

.Toastify__toast--error {
  background: #cc493f !important;
}
